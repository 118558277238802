import firebase from "firebase/app";
import { initFirebase } from "./init";

require("firebase/auth");
require("firebase/database");

const initialize = () => {
  initFirebase();
};

export const getFirebaeData = async (node, subNode) => {
  initialize();
  return await firebase
    .database()
    .ref("/" + node + "/")
    .child(subNode)
    .once("value")
    .then((snapshot) => {
      return snapshot.val();
    });
};

export const saveDataToFirebase = async (node, subNode, data) => {
  initialize();
  return await firebase.database().ref(node).child(subNode).set(data);
};

export const updateDataToFirebase = async (node, subNode, subWayNode,data) => {
    initialize();
    return await firebase.database().ref(node).child(subNode).child(subWayNode).push(data);
  };


  export const updateDataToFirebaseTwoNodeStructFB = async (node,subNode,data) => {
    initialize();
    return await firebase.database().ref(node).child(subNode).push(data);
  };