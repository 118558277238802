import firebase from "firebase/app";
import { firebaseConfig } from "../config/firebase";

export const initFirebase = async () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    await firebase
      .auth()
      .signInWithEmailAndPassword(
        "urwithdhanu@gmail.com",
        "Radhasoami"
      )
    .then((data) => console.log(data))
    .catch(error => console.log(error))
  } else {
    firebase.app(); // if already initialized, use that one
    await firebase
      .auth()
      .signInWithEmailAndPassword(
        "urwithdhanu@gmail.com",
        "Radhasoami"
      )
    .then((data) => console.log(data))
    .catch(error => console.log(error))
  }
};