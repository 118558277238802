
import React, { useState, useEffect } from 'react';
import './App.css';
import me from './images/agrofarming.jpg'
import ams from './images/banana.jfif'
import cms from './images/papaya.jfif'
import sms from './images/mousambi.jpg'
import gplay from './images/gplay.png'
import { saveData, getData } from './services/api';

function App() {

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [subj, setSubj] = useState('');
  const [msg, setMsg] = useState('');
  const [customerPref, setCustomerPref] = useState(null)

  useEffect(() => {
    getData('users', 'a').then(res => {
      console.log(res)
    })
  }, [])

  const handleSubmit = () => {
    const timeStamp = Date.now();
    const state = {
      username,
      email,
      subj,
      msg,
      timeStamp,
      customerPref
    }
    saveData('users', Date.now(), state).then(res => {
      alert("Thank you for the message ! Dhana will get back to you !")
    })
  }

  const handleNameChange = (e) => {
    setUsername(e.target.value)
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handleSelectChange = (e) => {
    setCustomerPref(e.target.value)
  }

  const handleMsgChange = (e) => {
    setMsg(e.target.value)
  }
  const handleSubChange = (e) => {
    setSubj(e.target.value)
  }

  return (
    <>
      <div className="scroll-up-btn">
        <i className="fas fa-angle-up"></i>
      </div>

      <nav className="navbar">
        <div className="max-width">
          <div className="logo"><a href="#">Krushi <span>farms.</span></a></div>
          <ul className="menu">
            <li><a href="#home" className="menu-btn">Home</a></li>
            <li><a href="#about" className="menu-btn">About</a></li>
            <li><a href="#services" className="menu-btn">Services</a></li>
            <li><a href="#skills" className="menu-btn">Specilzation</a></li>
            <li><a href="#teams" className="menu-btn">Products</a></li>
            <li><a href="#contact" className="menu-btn">Contact</a></li>
          </ul>
          <div className="menu-btn">
            <i className="fas fa-bars"></i>
          </div>
        </div>
      </nav>


      <section className="home" id="home">
        <div className="max-width">
          <div className="home-content">
            <div className="text-1">Hello, we are a b2b and b2c agro-ecology based organic farming products company</div>
            <div className="text-2">businesses to business agro-wholesale company.</div>
            <div className="text-3">And we are specialized at <span className="typing"></span></div>
            <a href="#contact">Contact us</a>
          </div>
        </div>
      </section>

      <section className="about" id="about">
        <div className="max-width">
          <h2 className="title">About us.</h2>
          <div className="about-content">
            <div className="column left">
              <img width="500" height="900" src={me} alt="" />
            </div>
            <div className="column right">
              <div className="text">We are an agro-ecological based company aimed at  delivering quality foods to people. <span className="typing-2"></span></div>
              <p>We have past & current experience of organic farming , we developed best practices in rganic farming. All we are focussed in community health and helping small business and farmers to get their best returns while at the same time delivering quality agro-ecological products. </p>
              <a href="#contact">Contact us</a>
            </div>
          </div>
        </div>
      </section>

      <section className="services" id="services">
        <div className="max-width">
          <h2 className="title">Our services</h2>
          <div className="serv-content">
            <div className="card">
              <div className="box">
                <i className="fas fa-paint-brush"></i>
                <div className="text">Connect with agriculture business</div>
                <p>We connect to retail and whole sale business to provide the fresh harvest directly from farmers.</p>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <i className="fas fa-chart-line"></i>
                <div className="text">Buy harvest</div>
                <p>We are here to provide best price for your harvest.We buy your harvest at your place and the settlements are done the same day</p>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <i className="fas fa-code"></i>
                <div className="text">Sell packaged agro-ecology products</div>
                <p>We deliver the agricultural products as per your business needs to your retail outlet. Our digital tracker helps you easily integrate with our systems to order more as per your demand.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="skills" id="skills">
        <div className="max-width">
          <h2 className="title">Our specialization</h2>
          <div className="skills-content">
            <div className="column left">
              <div className="text">Our Care for quality food.</div>
              <p>We do not use chemicals during farming. All we do is organic way of farming which needs lot of effort and care</p>
              <a href="#">Read more</a>
            </div>
            <div className="column right">
              <div className="bars">
                <div className="info">
                  <span>Organic farmong</span>
                  <span>100%</span>
                </div>
                <div className="line html"></div>
              </div>
              <div className="bars">
                <div className="info">
                  <span>Selling agro harvest</span>
                  <span>90%</span>
                </div>
                <div className="line css"></div>
              </div>
              <div className="bars">
                <div className="info">
                  <span>Buying harvest at best price</span>
                  <span>90%</span>
                </div>
                <div className="line js"></div>
              </div>
              <div className="bars">
                <div className="info">
                  <span>Connect with retailers</span>
                  <span>90%</span>
                </div>
                <div className="line php"></div>
              </div>
              <div className="bars">
                <div className="info">
                  <span>Bridge farmers with best buyers</span>
                  <span>100%</span>
                </div>
                <div className="line mysql"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="teams" id="teams">
        <div className="max-width">
          <h2 className="title">Our Products</h2>
          <div className="carousel owl-carousel">
            <div className="card">
              <div className="box">
                <img src={ams} alt="" />
                <div className="text">Banana</div>
                <p>We have 3 varieties of banana delivered to our customers.Karpoorva, Red banana and Chescal</p>
                <p>A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. </p>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <img src={sms} alt="" />
                <div className="text">Mousambi</div>
                <p>Citrus limetta, alternatively considered to be a cultivar of Citrus limon, C. limon 'Limetta', is a species of citrus, commonly known as mousami, musami, sweet lime, sweet lemon, and sweet limetta, it is a member of the sweet lemons. It is small and round like a common lime in shape.</p>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <img src={cms} alt="" />
                <div className="text">Papaya</div>

                <p>The papaya, papaw, or pawpaw is the plant Carica papaya, one of the 22 accepted species in the genus Carica of the family Caricaceae. It was first domesticated in Mesoamerica, within modern-day southern Mexico and Central America. In 2020, India produced 43% of the world supply of papayas.</p>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="contact" id="contact">
        <div className="max-width">
          <h2 className="title">Contact me</h2>
          <div className="contact-content">
            <div className="column left">
              <div className="text">Get in Touch</div>
              <p>Is your aim to give quality agriculturalfoods to people? Do you want to sell your harvest at best price ? Do you want fresh Agricultural harvest packaged and delivered at your retail outlet? Please contact us. </p>
              <div className="icons">
                <div className="row">
                  <i className="fas fa-user"></i>
                  <div className="info">
                    <div className="head">Name</div>
                    <div className="sub-title">Krushi Farms</div>
                  </div>
                </div>
                <div className="row">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="info">
                    <div className="head">Address</div>
                    <div className="sub-title">Hyderabad, India</div>
                  </div>
                </div>
                <div className="row">
                  <i className="fas fa-envelope"></i>
                  <div className="info">
                    <div className="head">Email</div>
                    <div className="sub-title">info@krushifarms.in</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column right">
              <div className="text">Message us</div>
              <form>
                <div className="fields">
                  <div className="field name">
                    <input onChange={handleNameChange} name='username' type="text" placeholder="Name" required />
                  </div>
                  <div className="field email">
                    <input onChange={handleEmailChange} name='emailphone' type="text" placeholder="Phone" required />
                  </div>
                  <div className="field">
                    <select onChange={handleSelectChange} className='select' name="buysell" id="buysellharvest" style={{ padding: '11px' }}>
                      <option value="nothingselected">Select preference</option>
                      <option value="buyharvest">Buy harvest</option>
                      <option value="sellharvest">Sell harvest</option>
                    </select>
                  </div>
                </div>
                <div className="field">
                  <input onChange={handleSubChange} name='subject' type="text" placeholder="Subject" required />
                </div>
                <div className="field textarea">
                  <textarea onChange={handleMsgChange} name='message' cols="30" rows="10" placeholder="Message.." required></textarea>
                </div>
                <div className="button-area">
                  <button onClick={handleSubmit} type="submit">Send message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <span> <a href="https://dtons.in/">DtonsSolutions</a> | <span className="far fa-copyright"></span> 2022 All rights reserved.</span>
      </footer>
    </>
  );
}

export default App;
