import {getFirebaeData,saveDataToFirebase,updateDataToFirebase,updateDataToFirebaseTwoNodeStructFB} from "./firebaseApi";

export const getData = async (node, subNode) => {
    return await getFirebaeData(node,subNode);
};

export const saveData = async (node, subNode,data) => {
    return await saveDataToFirebase(node,subNode,data);
};

export const updateData = async (node, subNode,subWayNode,data) => {
    return await updateDataToFirebase(node,subNode,subWayNode,data);
};

export const updateDataToFirebaseTwoNodeStruct= async (node,subNode,data) => {
    return await updateDataToFirebaseTwoNodeStructFB(node,subNode,data);
};